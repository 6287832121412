<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
        <img :src="require('@/assets/images/feedback.jpg')" style="width: 100%; border-radius: 10px 10px 0px 0px">
        <div class="card card-body" style="border-radius: 0px 0px 10px 10px">
            <div class="form-group">
            <h5 for="">الخدمة</h5>
            <select class="form-control" v-model="service">
                <option value="النظام">النظام بشكل عام</option>
                <option v-for="s in services" :key="s.title" :value="s.title">
                    {{ s.title }}
                </option>
            </select>
            </div>
            <div class="form-group">
            <h5 for="">رأيك / اقتراحك بالتفصيل</h5>
            <textarea class="form-control" v-model="content" placeholder="اكتب رأيك او اقتراحك هنا..." rows="3"></textarea>
            </div>
            <div class="form-group">
            <h5 for="">ما هو تقييمك للنظام الحالي؟</h5>
            <select class="form-control" v-model="rate">
                <option value="سيئ">سيئ</option>
                <option value="يحتاج بعض التحسينات">يحتاج بعض التحسينات</option>
                <option value="جيد">جيد</option>
                <option value="ممتاز">ممتاز</option>
            </select>
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-success" v-if="!sending" @click="send()">
                    ارسال لإدارة النظام الآن <i class="fa fa-arrow-left"></i>
                </button>
                <button class="btn btn-success" v-if="sending">
                    جاري الارسال...
                </button>
            </div>
        </div>
    </div>
    <div class="col-12 c">
        <div class="card card-body">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-striped table-striped table-sm">
                    <thead>
                        <th>
                            التاريخ
                        </th>
                        <th>
                            الخدمة
                        </th>
                        <th>
                            رأيك
                        </th>
                        <th>
                            المحتوى
                        </th>
                        <th>
                            استلمتها الادارة
                        </th>
                        <th>
                            رد الادارة
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="feedback in feedbacks" :key="feedback._id">
                            <td>
                                {{ feedback.date }}
                            </td>
                            <td>
                                {{ feedback.service }}
                            </td>
                            <td>
                                {{ feedback.rate }}
                            </td>
                            <td>
                                {{ feedback.content }}
                            </td>
                            <td>
                                <span class="btn btn-sm btn-success" v-if="feedback.seen"><i class="fa fa-check"></i> نعم</span>
                                <span class="btn btn-sm btn-warning" v-if="!feedback.seen"><i class="fa fa-clock-o"></i> في الانتظار</span>
                            </td>
                            <td>
                                {{ feedback.admin_reply }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            service: "النظام",
            services: [],
            rate: "جيد",
            content: "",
            sending: false,
            feedbacks: []
        }
    },
    created(){
        var g = this;
        this.loading = true
        $.post(api + '/general/services', {
            jwt: this.user.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                g.services = r.response
            }
        }).fail(function(){
            g.loading = false
        })
        g.getFeedbacks()
    },
    methods: {
        send(){
            var g = this;
            g.sending = true;
            $.post(api + '/user/general/feedback', {
                jwt: this.user.jwt,
                service: this.service,
                content: this.content,
                rate: this.rate
            }).then(function(r){
                g.sending = false;
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    g.getFeedbacks();
                    alert("تم الارسال لادارة النظام بنجاح!", 100)
                }
            }).fail(function(){
                g.sending = false;
                alert('حدث خطأ في الاتصال', 200)
            })
        },
        getFeedbacks(){
            var g = this;
            $.post(api + '/user/general/feedbacks', {
                jwt: this.user.jwt
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.feedbacks = r.response
                }
            }).fail(function(){
                g.loading = false
            })
        }
    }
}
</script>

<style>

</style>